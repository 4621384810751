import clsx from 'clsx';
import Icon from 'components/01-atoms/Icon';
import type { Type as IconType } from 'components/01-atoms/Icon';
import Flex from 'components/01-atoms/Flex';

type Type = 'date' | 'location';

type Size = 'medium' | 'small';

type Props = {
  size?: Size;
  type: Type;
  value: string;
};

const getIndicatorIconType = (type: Type): IconType => {
  switch (type) {
    case 'date':
      return 'calendar';
    case 'location':
      return 'pin';
    default:
      return null;
  }
};

const Indicator = ({ size = 'medium', type, value }: Props) => (
  <Flex className="items-center gap-x-3">
    <Icon
      className="text-white"
      type={getIndicatorIconType(type)}
      width={size === 'medium' ? 24 : 20}
    />
    <span
      className={clsx(
        'font-plus-jakarta-sans text-white font-light col-span-4 self-center',
        {
          'text-12 narrow:text-14': size === 'small',
          'text-14 narrow:text-16': size === 'medium'
        }
      )}
      suppressHydrationWarning={type === 'date'}
    >
      {value}
    </span>
  </Flex>
);

export default Indicator;
