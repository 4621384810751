import clsx from 'clsx';
import type { ReactNode } from 'react';

type MaxSize = 'large' | 'medium' | 'small' | 'x-large';

type Props = {
  as?: keyof JSX.IntrinsicElements;
  children: ReactNode;
  classes?: string;
  maxSize?: MaxSize;
  overflowHidden?: boolean;
};

const Container = ({
  as = 'section',
  children,
  classes,
  maxSize,
  overflowHidden = false
}: Props) => {
  const Tag = as;
  return (
    /* we use overflow clip instead of hidden to not break margin-scroll-top property */
    <Tag className={clsx({ 'w-full overflow-clip': overflowHidden })}>
      <div
        className={clsx(
          'w-full mx-auto px-5 narrow-x:px-12',
          {
            'max-w-[1144px]': maxSize === 'small',
            'max-w-[1400px]': maxSize === 'medium',
            'max-w-[1800px]': maxSize === 'large',
            'max-w-[2600px]': maxSize === 'x-large'
          },
          classes
        )}
      >
        {children}
      </div>
    </Tag>
  );
};

export default Container;
