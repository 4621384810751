import clsx from 'clsx';

type Color = 'black';

type Props = {
  classes?: string;
  color?: Color;
};

const Mask = ({ classes, color = 'black' }: Props) => (
  <div
    className={clsx(classes, 'absolute w-full h-full', {
      'bg-black': color === 'black'
    })}
  />
);

export default Mask;
