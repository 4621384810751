import slugify from 'slugify';

import { getMediaTitle } from './media/getMediaTitle';

export const videoUrlSlug = (title: string) =>
  slugify(getMediaTitle(title), {
    lower: true,
    // eslint-disable-next-line no-useless-escape
    remove: /[*+~.()'"!?$&*#:@\/]/g
  });
