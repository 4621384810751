/* eslint-disable react/no-unused-prop-types */
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { useRef, useState, useCallback, useEffect, Children } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { useTailwindBreakpoint } from 'helpers/hooks/useTailwindBreakpoint';
import Icon from 'components/01-atoms/Icon';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useFluidElements } from 'helpers/hooks/useFluidElements';

import CarouselGradient from './CarouselGradient';
import { getEmblaParams } from './helpers';
import CarouselIndex from './CarouselIndex';

export type DesktopViewLimit = 4 | 6;

type Props = {
  /**
   * Classes that go on the Icon on hover of the button, such as background color. Only applicable when showBtnHover is true.
   */
  buttonHoverClasses?: string;
  /**
   * Classes on top-level component div; px-11 == makes arrows look like OUTSIDE container (e.g. watch); px-22 == looks like INSIDE (e.g. learn)
   */
  classes?: string;
  /**
   * The number of slides on desktop - between narrow-x and wide-x breakpoints
   */
  desktopViewLimit?: DesktopViewLimit;
  /**
   * Determines if carousel should have fluid slides number
   */
  isFluid?: boolean;
  /**
   * Defines the percentage of the partial slide to display on mobile screens, if showPartialNextSlide is set to true.
   * This should be a number between 0 and 1
   */
  mobilePartialNextSlidePercentage?: number;
  /**
   * Defines the percentage of the partial slide to display, if showPartialNextSlide is set to true.
   * This should be a number between 0 and 1
   */
  partialNextSlidePercentage?: number;
  /**
   * Switches indicator that the carousel is now scrolling
   */
  setIsScrolling?: Dispatch<SetStateAction<boolean>>;
  /**
   * Determines whether or not a hover state is shown on the buttons.
   */
  showBtnHover?: boolean;
  /**
   * Number that determines where the carousel starts
   */
  startIndex?: number;
  /**
   * Classes on embla viewport component div; used if there is a drop shadow, hover scale state, etc. on card that isn't cut off by overflow-hidden
   */
  viewportClasses?: string;
  /**
   * Determines whether or not to show the index on the carousel
   */
  withIndex?: boolean;
};

export const Carousel = ({
  buttonHoverClasses = 'bg-[#0D5267]',
  children,
  classes = '',
  desktopViewLimit = 6,
  isFluid = true,
  mobilePartialNextSlidePercentage = 0.2,
  partialNextSlidePercentage = 0,
  setIsScrolling,
  showBtnHover = false,
  startIndex = 0,
  viewportClasses = '',
  withIndex
}: PropsWithChildren<Props>) => {
  const slides = Children.toArray(children);

  const { isReady, isScreenNarrowerThan } = useTailwindBreakpoint();
  const router = useRouter();

  const isScreenNarrowerThanNarrowX = isScreenNarrowerThan('narrow-x');
  const emblaContainer = useRef(null);

  const { carouselViewLimit } = useFluidElements(emblaContainer, {
    desktopViewLimit,
    enabled: isFluid
  });

  const slidesVisible = isScreenNarrowerThanNarrowX
    ? carouselViewLimit + mobilePartialNextSlidePercentage
    : carouselViewLimit + partialNextSlidePercentage;
  const slideWidth = 100 / slidesVisible;

  // Don't output arrows unless we are tablet+ and there are more slides than view limit
  const showCarouselArrows =
    !isScreenNarrowerThanNarrowX && slides.length > carouselViewLimit;

  const emblaParams = getEmblaParams({
    currentViewLimit: carouselViewLimit,
    isMobile: isScreenNarrowerThanNarrowX,
    startIndex
  });

  const [emblaViewport, embla] = useEmblaCarousel(emblaParams);
  const [slidesInView, setSlidesInView] = useState([]);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  useEffect(() => {
    if (!embla) return;
    embla.reInit();
    embla.scrollTo(startIndex, true);
    setTimeout(() => {
      setSlidesInView(embla.slidesInView());
      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    }, 0);
  }, [embla, router.query, startIndex]);

  const handlePreviousClick = useCallback(
    () => embla && embla.scrollPrev(),
    [embla]
  );

  const handleNextClick = useCallback(
    () => embla && embla.scrollNext(),
    [embla]
  );

  const handleSelect = useCallback(() => {
    if (!embla) return;
    setSlidesInView(embla.slidesInView(true));
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  // handles the hiding and showing of the navigation arrows
  useEffect(() => {
    if (!embla) return;
    embla.on('select', handleSelect);
    handleSelect();
    setPrevBtnEnabled(false);
    setNextBtnEnabled(true);
  }, [embla, handleSelect]);

  // handles the info if the carousel is scrolling
  useEffect(() => {
    if (!embla || !setIsScrolling) return;
    embla.on('select', () => setIsScrolling(true));
    embla.on('settle', () => setIsScrolling(false));
  }, [embla, setIsScrolling]);

  if (!carouselViewLimit) return null;

  return (
    <div className={clsx('narrow-x:px-11', classes)}>
      <div className="embla narrow-x:-mx-11 relative">
        {prevBtnEnabled && <CarouselGradient side="left" />}
        {nextBtnEnabled && <CarouselGradient side="right" />}
        <div
          ref={emblaViewport}
          className={clsx('embla-viewport w-full', viewportClasses)}
        >
          <div
            ref={emblaContainer}
            className="embla-container flex -ml-4 narrow-x:-ml-6"
          >
            {/* eslint-disable react/no-array-index-key */}
            {slides.map((slide, index) => (
              <div
                key={index}
                className={clsx('relative pl-4 narrow-x:pl-6', {
                  'min-w-[62.5%] max-w-[62.5%] narrow-x:min-w-[25%] narrow-x:max-w-[25%]':
                    !isReady,
                  'pointer-events-none': !slidesInView.includes(index)
                })}
                style={
                  isReady
                    ? {
                        maxWidth: `${slideWidth}%`,
                        minWidth: `${slideWidth}%`
                      }
                    : undefined
                }
              >
                {withIndex && <CarouselIndex index={index + 1} />}
                {slide}
              </div>
            ))}
            {/* eslint-enable react/no-array-index-key */}
          </div>
        </div>

        {showCarouselArrows && (
          <>
            <button
              aria-label="Previous Button"
              className="absolute narrow-x:-left-7 top-1/2 transform -translate-y-1/2 group flex justify-center items-center w-8 h-8 cursor-pointer z-1"
              disabled={!prevBtnEnabled}
              onClick={handlePreviousClick}
              type="button"
            >
              {prevBtnEnabled && (
                <>
                  {showBtnHover && (
                    <div
                      className={clsx(
                        `rounded-full absolute hidden group-hover:inline-block transform -translate-y-1/2 top-1/2 h-[48px] w-[48px] cursor-pointer`,
                        buttonHoverClasses
                      )}
                      style={{ left: '-20px' }}
                    />
                  )}
                  <Icon className="text-white" type="chevronLeft" width={24} />
                </>
              )}
            </button>
            <button
              aria-label="Next Button"
              className="absolute narrow-x:-right-7 top-1/2 transform -translate-y-1/2 group flex justify-center items-center w-8 h-8 cursor-pointer z-1"
              disabled={!nextBtnEnabled}
              onClick={handleNextClick}
              type="button"
            >
              {nextBtnEnabled && (
                <>
                  {showBtnHover && (
                    <div
                      className={`rounded-full absolute hidden group-hover:inline-block transform -translate-y-1/2 top-1/2 h-[48px] w-[48px] cursor-pointer ${buttonHoverClasses}`}
                      style={{ right: '-16px' }}
                    />
                  )}
                  <Icon className="text-white" type="chevronRight" width={24} />
                </>
              )}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Carousel;
