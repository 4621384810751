import type { MutableRefObject } from 'react';
import type { DesktopViewLimit } from 'components/03-organisms/Carousel';

// two times single margin so we don't have to calculate every time it changes
const PAGE_MARGIN = 2 * 48;
const POSTER_WIDTH_WITH_SPACING_BY_VIEWLIMIT: Record<DesktopViewLimit, number> =
  {
    4: 324,
    6: 220
  };
// when relative to window first poster won't have to have a left spacing
const SURPLUS_POSTER_SPACING = 24;
// the width from which it shouldn't add more elements
const UNFLUID_WIDTH = 2375;

export const calculateAmountOfElementsInsideGridRow = (
  containerRef?: MutableRefObject<HTMLDivElement>,
  desktopViewLimit: DesktopViewLimit = 6
) => {
  const posterWidth = POSTER_WIDTH_WITH_SPACING_BY_VIEWLIMIT[desktopViewLimit];

  const { innerWidth } = window;

  // relative to container (e.g. carousel container)
  if (containerRef?.current) {
    if (innerWidth > UNFLUID_WIDTH) {
      return Math.floor((UNFLUID_WIDTH - PAGE_MARGIN) / posterWidth);
    }

    const { clientWidth } = containerRef.current;

    return Math.floor(clientWidth / posterWidth);
  }

  // relative to window width
  if (innerWidth > UNFLUID_WIDTH) {
    return Math.floor(
      (UNFLUID_WIDTH - PAGE_MARGIN + SURPLUS_POSTER_SPACING) / posterWidth
    );
  }

  return Math.floor(
    (innerWidth - PAGE_MARGIN + SURPLUS_POSTER_SPACING) / posterWidth
  );
};
