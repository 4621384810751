import clsx from 'clsx';

type Direction = 'bottom' | 'left' | 'right' | 'top';
type Variant = 'black' | 'primary';

type Props = {
  classes?: string;
  direction: Direction;
  variant?: Variant;
};

const Gradient = ({ classes, direction, variant = 'primary' }: Props) => (
  <div
    className={clsx(classes, 'absolute w-full h-full', {
      'bg-gradient-to-b': direction === 'bottom',
      'bg-gradient-to-l': direction === 'left',
      'bg-gradient-to-r': direction === 'right',
      'bg-gradient-to-t': direction === 'top',
      'from-black': variant === 'black',
      'from-neutral-800': variant === 'primary'
    })}
  />
);

export default Gradient;
