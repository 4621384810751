import { useEffect, useRef, useState } from 'react';
import Gradient from 'components/01-atoms/Gradient';
import clsx from 'clsx';
import { useTailwindBreakpoint } from 'helpers/hooks/useTailwindBreakpoint';
import { useWindowSize } from 'helpers/hooks/useWindowSize';

import { getDistanceStyle } from './helpers';

export type Side = 'left' | 'right';

export type Props = {
  side: Side;
};

const CarouselGradient = ({ side }: Props) => {
  const { isScreenNarrowerThan } = useTailwindBreakpoint();
  const isMobile = isScreenNarrowerThan('narrow-x');
  const [distanceStyle, setDistanceStyle] = useState({});
  const { width: windowWidth } = useWindowSize();
  const isLeft = side === 'left';
  const isRight = side === 'right';

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (ref?.current) {
      const carouselRoot = ref.current.parentElement;
      setDistanceStyle(getDistanceStyle(side, carouselRoot));
    }
  }, [ref, side, windowWidth]);

  return (
    <div
      ref={ref}
      className={clsx('absolute top-0 bottom-0 z-1', {
        'left-[calc(100%+20px)]': !isMobile && isRight,
        'left-full': isMobile && isRight,
        'right-[calc(100%+20px)]': !isMobile && isLeft,
        'right-full': isMobile && isLeft
      })}
      style={distanceStyle}
    >
      <Gradient direction={isLeft ? 'right' : 'left'} variant="black" />
    </div>
  );
};

export default CarouselGradient;
