import type { EmblaOptionsType } from 'embla-carousel-react';

import type { Side } from './CarouselGradient';

const getDistance = (side: Side, root: HTMLElement) =>
  side === 'left'
    ? root.offsetLeft
    : window.innerWidth - root.offsetLeft - root.offsetWidth;

export const getDistanceStyle = (side: Side, root: HTMLElement) => ({
  [side]: -getDistance(side, root)
});

type Input = {
  currentViewLimit: number;
  isMobile: boolean;
  startIndex: number;
};

export const getEmblaParams = ({
  currentViewLimit,
  isMobile,
  startIndex
}: Input): Partial<EmblaOptionsType> => {
  const slidesToScroll = isMobile
    ? currentViewLimit
    : Math.floor(currentViewLimit / 2);

  return {
    align: 'start',
    containScroll: 'trimSnaps',
    dragFree: isMobile,
    draggable: isMobile,
    slidesToScroll,
    startIndex: Math.floor(startIndex / slidesToScroll)
  };
};
