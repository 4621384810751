import Badge from 'components/01-atoms/Badge';
import { EventState } from 'helpers/types/jwplayer-subgraph';

export type Props = {
  state: EventState;
};

const EventBadgeManager = ({ state }: Props) => {
  switch (state) {
    case EventState.Live:
      return <Badge text="Live Now" variant="secondary" />;
    case EventState.Vod:
      return <Badge text="Replay" variant="primary" />;
    case EventState.Upcoming:
      return <Badge text="Coming Soon" variant="primary" />;
    default:
      return null;
  }
};

export default EventBadgeManager;
