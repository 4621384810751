import type { MutableRefObject } from 'react';
import { useRef, useEffect, useState } from 'react';
import { calculateAmountOfElementsInsideGridRow } from 'helpers/utils/calculateAmountOfElementsInsideGridRow';
import type { DesktopViewLimit } from 'components/03-organisms/Carousel';

import { useTailwindBreakpoint } from './useTailwindBreakpoint';

type Options = {
  desktopViewLimit?: DesktopViewLimit;
  enabled?: boolean;
};

export const useFluidElements = (
  containerRef?: MutableRefObject<HTMLDivElement>,
  options: Options = { enabled: true }
) => {
  const [elementsInGridRow, setElementsInGridRow] = useState(null);
  const { isReady, isScreenNarrowerThan, isScreenWiderOrEqualTo } =
    useTailwindBreakpoint();
  const isOverWideX = isScreenWiderOrEqualTo('wide-x');
  const isScreenNarrowerThanNarrowXXX = isScreenNarrowerThan('narrow-xxx');
  const isScreenNarrowerThanNarrowXX = isScreenNarrowerThan('narrow-xx');
  const isScreenNarrowerThanNarrowX = isScreenNarrowerThan('narrow-x');
  const isScreenNarrowerThanNarrow = isScreenNarrowerThan('narrow');
  const animationIdRef = useRef(null);

  /* wide-x is the breakpoint where we consider carousel to be fluid */
  const shouldCalculateFluid = options.enabled && isOverWideX;

  const getViewLimit = () => {
    if (shouldCalculateFluid) return elementsInGridRow;
    if (isScreenNarrowerThanNarrowXXX) return 2;
    if (isScreenNarrowerThanNarrowXX) return 3;
    if (isScreenNarrowerThanNarrow) return 4;
    return options.desktopViewLimit || 6;
  };

  const carouselViewLimit = getViewLimit();

  useEffect(() => {
    const handleResize = () => {
      if (isOverWideX) {
        const amountOfElementsInsideGridRow =
          calculateAmountOfElementsInsideGridRow(
            containerRef,
            options.desktopViewLimit
          );
        setElementsInGridRow(amountOfElementsInsideGridRow);
      }
    };

    handleResize();

    const rafHandleResize = () => {
      if (animationIdRef.current) {
        cancelAnimationFrame(animationIdRef.current);
      }
      animationIdRef.current = requestAnimationFrame(handleResize);
    };

    window.addEventListener('resize', rafHandleResize);

    return () => window.removeEventListener('resize', rafHandleResize);
  }, [containerRef, isOverWideX, options.desktopViewLimit]);

  const getNextPageElements = () => {
    if (!isReady) return undefined;
    if (isOverWideX) return elementsInGridRow * 2;
    if (isScreenNarrowerThanNarrowXXX) return 4;
    if (isScreenNarrowerThanNarrowX) return 6;
    return 8;
  };

  const nextPageElements = getNextPageElements();

  return { carouselViewLimit, elementsInGridRow, nextPageElements };
};
