import clsx from 'clsx';
import type { ReactNode } from 'react';

type Props = {
  classes?: string;
  data: ReactNode[];
};

const HorizontalListWithDivider = ({ classes, data }: Props) => {
  if (!data) return null;

  const nonNullableData = data.filter((item) => item);
  const renderItem = (item, index) => (
    <div
      key={index}
      className={clsx(
        'relative flex items-center',
        {
          'after:h-3 after:absolute after:right-0 after:w-[1px] after:bg-neutral-700 pr-3 mr-3':
            index !== nonNullableData.length - 1
        },
        classes
      )}
      role="listitem"
      // dates are mismatched between the server and client when item is dynamically rendered
      // React 17 also had the error but it was suppressed by default
      suppressHydrationWarning
    >
      {item}
    </div>
  );

  return (
    <div
      className="flex items-center flex-wrap text-16 font-plus-jakarta-sans font-light text-white capitalize"
      role="list"
    >
      {nonNullableData.map(renderItem)}
    </div>
  );
};

export default HorizontalListWithDivider;
