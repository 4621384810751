import clsx from 'clsx';

type Props = {
  className?: string;
  index: number;
};

const CarouselIndex = ({ className, index }: Props) => {
  const Indicator = Intl.NumberFormat('en-US', {
    minimumIntegerDigits: 2
  });

  return (
    <div
      className={clsx(
        'font-plus-jakarta-sans font-700 text-transparent text-[100px] narrow-x:text-[70px] wide:text-[132px] leading-[130px] narrow-x:leading-[80px] wide:leading-[130px] -mb-8 narrow-x:-mb-4 wide:-mb-5 -mt-6 narrow-x:-mt-2 pointer-events-none',
        'carousel-index-gradient bg-clip-text',
        className
      )}
    >
      {Indicator.format(index)}
    </div>
  );
};

export default CarouselIndex;
