import clsx from 'clsx';
import Icon from 'components/01-atoms/Icon';
import Mask from 'components/01-atoms/Mask';
import type { ReactNode } from 'react';

export type Effect = 'mask' | 'play' | 'scale';

type Props = {
  children: ReactNode;
  effects?: Effect[];
  iconSize?: number;
};

const Hoverable = ({
  children,
  effects = ['scale', 'play', 'mask'],
  iconSize = 48
}: Props) => (
  <div
    className={clsx('aspect-h-9 aspect-w-16 relative group', {
      'narrow-x:transition-transform narrow-x:hover:scale-105 narrow-x:duration-300':
        effects.includes('scale')
    })}
  >
    {children}
    {effects.includes('play') && (
      <div
        className={clsx(
          'hidden narrow-x:flex items-center justify-center z-1',
          'narrow-x:opacity-0 narrow-x:group-hover:opacity-100 narrow-x:duration-300'
        )}
      >
        <Icon className="text-white" type="playFull" width={iconSize} />
      </div>
    )}
    {effects.includes('mask') && (
      <Mask classes="opacity-0 narrow-x:group-hover:opacity-50 narrow-x:duration-300" />
    )}
  </div>
);

export default Hoverable;
