import type { MediaItem } from 'helpers/types/jwplayer-subgraph';

export const removeNullableContent = <
  T extends MediaItem | { mediaItem?: MediaItem }
>(
  data: Array<T>
) =>
  data?.filter((item) =>
    'mediaItem' in item ? Boolean(item.mediaItem) : Boolean(item)
  ) || [];
