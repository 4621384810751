type Query = Nullable<ConstructorParameters<typeof URLSearchParams>[0]>;

export const createURL = (pathname: string, querystring: Query) => {
  if (!querystring) return pathname;

  /* Used URL constructor here for more flexibility of creating urls.
    The production URL is passed here only to satisfy URL constructor
    it doesn't matter in the further mapping, since we use only pathname and search */
  const base = 'https://watch.outsideonline.com/';
  const url = new URL(pathname, base);

  const searchParams = new URLSearchParams(url.search);

  const appliedSearchParams = new URLSearchParams(querystring);

  appliedSearchParams.forEach((value, key) => {
    searchParams.set(key, value);
  });

  searchParams.sort();

  url.search = searchParams.toString();

  return `${url.pathname}${url.search}`;
};
