import clsx from 'clsx';
import type { HTMLProps, PropsWithChildren } from 'react';

import Box from '../Box';

type Props = HTMLProps<HTMLDivElement> & {
  className?: string;
};

const Flex = ({ children, className, ...rest }: PropsWithChildren<Props>) => (
  <Box {...rest} className={clsx(className, 'flex')}>
    {children}
  </Box>
);

export default Flex;
